import {
    // Navigate,
    Outlet,
    // useLocation
} from 'react-router-dom';

// import { useAuth } from '../hooks';
// import { URLS } from '../routes/urls';

export default function PrivateRoute() {
    /**
     * Commented out for now until we have a need for it.
     */
    // const { isLoggedIn } = useAuth();
    // const location = useLocation();

    // if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected.
    // return <Navigate replace state={{ from: location }} to={URLS.Login.path} />;
    // }

    return <Outlet />;
}
