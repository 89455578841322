import { MenuItemProps } from '@keyliving/component-lib';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import PublicLayout from '../components/layout/PublicLayout';
import NoMatch from '../pages/NoMatch';
import { URLS } from './urls';

const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('../pages/ForgotPasswordSuccess'));
const Login = lazy(() => import('../pages/Login'));
const MagicLink = lazy(() => import('../pages/MagicLink'));
const MagicLinkSuccess = lazy(() => import('../pages/MagicLinkSuccess'));
const NewPassword = lazy(() => import('../pages/NewPassword'));

/**
 * These are public routes that the app needs. These are used
 * in App.tsx to create these routes.
 */
export const publicRoutes: RouteObject[] = [
    {
        path: '/',
        element: <PublicLayout />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Navigate replace to={URLS.Login.path} />
                    </Suspense>
                ),
            },
            {
                path: URLS.Login.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <Login />
                    </Suspense>
                ),
            },
            {
                path: URLS.ForgotPassword.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPassword />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.ForgotPassword.Success.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <ForgotPasswordSuccess />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.MagicLink.path,
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <MagicLink />
                            </Suspense>
                        ),
                    },
                    {
                        path: URLS.MagicLink.Success.path,
                        element: (
                            <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                                <MagicLinkSuccess />
                            </Suspense>
                        ),
                    },
                ],
            },
            {
                path: URLS.NewPassword.path,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <NewPassword />
                    </Suspense>
                ),
            },
            {
                path: '*',
                element: <NoMatch />,
            },
        ],
    },
];

/**
 * These are routes for logged in users only. We use this array
 * to create the protected routes in App.tsx.
 */
export const authRoutes: MenuItemProps[] = [
    {
        path: URLS.Profile.path,
        label: 'profile',
        showInMenu: false,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                        <div>Edit profile placeholder</div>
                    </Suspense>
                ),
            },
        ],
    },
];
