import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';

import PoweredByKey from '../../PoweredByKey';
import classes from './PublicLayout.module.scss';

const cx = classNames.bind(classes);

export default function PublicLayout() {
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <div className={cx('content-wrapper')}>
                    <Outlet />
                </div>
                <div className={classes.logo}>
                    <PoweredByKey />
                </div>
            </div>
        </div>
    );
}
