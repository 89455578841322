import { route, types } from 'react-router-typesafe-routes/dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { string } from 'zod';

/**
 * NOTE: Validation helpers catch parsing and validation errors
 * and replace values with undefined.
 *
 * e.g. zod(string().email()) - Requires the email passed in
 * the url to be a valid email. Otherwise the value
 * returned by useTypedSearchParams for email
 * will be undefined
 */

const DEFAULT_REDIRECT_URL = `${window.location.protocol}//keyown.com`;

export const SHARED_URL_PARAMS = route('', {
    searchParams: {
        email: zod(string().email()),
        redirectUrl: zod(string().url()).default(DEFAULT_REDIRECT_URL),
        org: zod(string()),
    },
});

export const URLS = {
    ForgotPassword: route('forgot-password', types(SHARED_URL_PARAMS), {
        Success: route('success'),
    }),
    Login: route(
        'login',
        types({
            searchParams: {
                token: zod(string()),
            },
        })(SHARED_URL_PARAMS)
    ),
    MagicLink: route('magic-link', types(SHARED_URL_PARAMS), {
        Success: route('success'),
    }),
    NewPassword: route(
        'new-password',
        types({
            searchParams: {
                token: zod(string()),
            },
        })(SHARED_URL_PARAMS)
    ),
    Profile: route('profile'),
};
