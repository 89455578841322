import { IconProps } from './types';

export default function EnvelopeIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M3 4.75h18s1.5 0 1.5 1.5v12s0 1.5-1.5 1.5H3s-1.5 0-1.5-1.5v-12s0-1.5 1.5-1.5"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
            <path
                d="m22.161 5.3-8.144 6.264a3.308 3.308 0 0 1-4.034 0L1.839 5.3"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
            />
        </svg>
    );
}
