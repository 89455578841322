import { ReactComponent as KeyLogo } from '../../static/key-logo-muted.svg';
import classes from './PoweredByKey.module.scss';

export default function PoweredByKey() {
    return (
        <>
            <div className={classes['powered-by']}>Powered by</div>
            <KeyLogo className={classes['key-logo']} height={24} width={48} />
        </>
    );
}
